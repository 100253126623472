$(document).on('turbolinks:load', function() {

  // BOTTLE QUICK SEARCH AUTOCOMPLETE
  if ($('#bottleSearchInput').length) {
    $('#bottleSearchInput').autocomplete({
      source: function(request, response) {
        $.get(
          $('#bottleSearchInput').data('url'),
          "search=" + request.term,
          function(data) {
            response(data)
          }
        )
      },
      select: function(event, ui) {
        window.location = ui.item.url
      },
      delay: 200,
      minLength: 2
    }).data('ui-autocomplete')._renderItem = function(ul, item) {
      return $( "<li></li>" ).data("item.autocomplete", item)
        .addClass('bottle-dropdown__item')
        .append( `<div class='bottle-dropdown__content'><img src='${item.img_src}' alt="" class='bottle-dropdown__img'/>${item.label}</div>` )
        .appendTo( ul );
    };
  }

  // ASSORTMENTS BOTTLE QUICK SEARCH / ADD AUTOCOMPLETE
  if ($('#assortmentBottleSearchInput').length) {
    $('#assortmentBottleSearchInput').autocomplete({
      source: function(request, response) {
        $.get(
          $('#assortmentBottleSearchInput').data('url'),
          "search=" + request.term,
          function(data) {
            response(data)
          }
        )
      },
      select: function(event, ui) {
        $.post(
          ui.item.url,
          { assortment: {
              bottle_id: ui.item.id
            }
          }
        )
      },
      delay: 200,
      minLength: 2
    }).data('ui-autocomplete')._renderItem = function(ul, item) {
      return $( "<li></li>" ).data("item.autocomplete", item)
        .addClass('bottle-dropdown__item')
        .append( `<div class='bottle-dropdown__content'><img src='${item.img_src}' alt="" class='bottle-dropdown__img'/>${item.label}</div>` )
        .appendTo( ul );
    };
  }


  // CLIENT QUICK SEARCH AUTOCOMPLETE
  let defaultInputSize = 20;
  if ($('#clientSearchInput').length) {
    $('#searchClientsIcon').on('click', function() {
      $('#clientSearchInput').focus();
    });
    $('#clientSearchInput').on('focus', function() {
      if (($(this).data('value').length + 5) < defaultInputSize) {
        this.size = defaultInputSize;
      }
      this.value = '';
    });

    $('#clientSearchInput').on('focusout', function() {
      this.value = $(this).data('value') || '';
      this.size = ($(this).data('value').length + 5) || defaultInputSize;
    });

    var clientAutocomplete = $('#clientSearchInput').autocomplete({
      source: function(request, response) {
        $.get(
          $('#clientSearchInput').data('url'),
          "search=" + request.term,
          function(data) {
            response(data)
          }
        )
      },
      select: function(event, ui) {
        event.preventDefault();
        $.ajax({
          url: ui.item.url,
          method: 'put'
        })
      },
      delay: 200,
      minLength: 2,
      position: {
        my: "left+1 top+2",
        at: "left bottom",
        collision: "none"
      }
    });

    clientAutocomplete.data('ui-autocomplete')._renderItem = function(ul, item) {
        return $( '<li></li>' ).data("item.autocomplete", item)
          .addClass('dropdown-item')
          .append( `<div>${item.label}</div>` )
          .appendTo( ul );
    };
  }
});