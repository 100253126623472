calculateDiscountedPrice = (price, discount) => {
  return (parseFloat(price) * (1 - (parseFloat(discount) / 100)));
};

calculateMarginRate = (price, cost) => {
  return (((parseFloat(price) - parseFloat(cost)) / parseFloat(price)) * 100);
};

App.ItemDiscount = class {
    constructor(discountId, price, discount, cost) {
      this.discountId = discountId;
      this.cost = parseFloat(cost);
      this.state = {
        price: parseFloat(price),
        newPrice: calculateDiscountedPrice(price, discount),
        marginRate: calculateMarginRate(price, cost),
        newMarginRate: calculateMarginRate(calculateDiscountedPrice(price, discount), cost),
        amount: parseFloat(price) - calculateDiscountedPrice(price, discount),
        percentage: parseFloat(discount) || 0.0
      };
      this.initialState = {
        ...this.state
      };
      this.defaultState = {
        price: parseFloat(price),
        newPrice: parseFloat(price),
        marginRate: calculateMarginRate(price, cost),
        newMarginRate: calculateMarginRate(price, cost),
        amount: 0.0,
        percentage: 0.0
      };
      this.bind();
    };

    bind() {
      this.bindInputChange();
      this.bindResetButton();
      this.bindDeleteButton();
    }

    bindInputChange = () => {
      $('.discount-input').unbind().on('change', (elmt) => {
        let discountValue = elmt.target.value;
        let discountType = $(elmt.target).data('discount-type');
        this.updateNewPrice(discountValue, discountType);
        this.resetOtherInputs(discountType);
      })
    };

    bindResetButton = () => {
      $(`#${this.discountId} .discount-reset`).unbind().on('click', (e) => {
        e.preventDefault();
        this.resetAll();
      });
    };

    bindDeleteButton = () => {
      $(`#${this.discountId} .discount-delete`).unbind().on('click', (e) => {
        e.preventDefault();
        this.deleteAll();
      });
    };

    calculateNewPrice = (value, type) => {
      if (isNaN(parseFloat(value))) { return };
      let newPrice = this.state.newPrice;
      switch (type) {
        case 'percentage' :
          newPrice = this.state.price * (1 - (value / 100));
          break;
        case 'amount' :
          newPrice = this.state.price - value;
          break;
        case 'newPrice' :
          newPrice = parseFloat(value);
          break;
      }
      return newPrice;
    };

    calculateNewMarginRate = (price, cost) => {
      return calculateMarginRate(price, cost);
    };

    changeState = (key, value) => {
      this.state[key] = value;
    };

    checkPriceIsValid = (price) => {
      return this.minPrice() <= price
    };

    deleteDiscount = () => {
      this.state = {
        ...this.defaultState
      }
    };

    deleteAll = () => {
      this.deleteDiscount();
      this.resetOtherInputs(null);
      this.updateDiscountFieldValue();
      this.renderDiscountValues();
      $(`#${this.discountId} .item-discount__submit-button`).trigger('click');
    };

    minPrice = () => {
      return this.cost;
    };

    resetOtherInputs = (type) => {
      $(`#${this.discountId} form .discount-input:not([data-discount-type=${type}])`).val(null)
    };

    resetAll = () => {
      this.resetState();
      this.resetOtherInputs(null);
      this.renderDiscountValues();
      this.updateDiscountFieldValue();
    };

    resetState = () => {
      this.state = {
        ...this.initialState
      }
    };

    updateDiscountFieldValue = () => {
      $(`#${this.discountId} form input[id='hidden_discount_input']`).val(this.state.percentage.toFixed(2));
    };

    updateDiscountValues = () => {
      let cost = this.cost;
      let price = this.state.price;
      let newPrice = this.state.newPrice;
      let newAmount = price - newPrice;
      let newPercentage = (newAmount / price) * 100;
      let newMarginRate = this.calculateNewMarginRate(newPrice, cost);

      this.changeState('amount', newAmount);
      this.changeState('percentage', newPercentage);
      this.changeState('newMarginRate', newMarginRate);
      this.updateDiscountFieldValue();
    };

    updateNewPrice = (value, type) => {
      let newPrice = this.calculateNewPrice(value, type);
      this.changeState('newPrice', newPrice);
      this.updateDiscountValues();
      this.renderDiscountValues();
    };

    renderDiscountValues = () => {
      $.each(['newPrice', 'percentage', 'amount', 'newMarginRate'], (index, elmt) => {
        $('#' + this.discountId + ' ' + 'form' + ' ' + ".discount-" + elmt).text(this.state[elmt].toFixed(2));
      });
      if (this.checkPriceIsValid(this.state.newPrice)) {
        $('#' + this.discountId + ' ' + 'form' + ' ' + ".item-discount__discounted-margin-rate").removeClass('is-invalid');
        $('#' + this.discountId + ' ' + 'form' + ' ' + ".item-discount__invalid-margin").addClass('invisible');
      } else {
        $('#' + this.discountId + ' ' + 'form' + ' ' + ".item-discount__discounted-margin-rate").addClass('is-invalid');
        $('#' + this.discountId + ' ' + 'form' + ' ' + ".item-discount__invalid-margin").removeClass('invisible');
      }
    }
};