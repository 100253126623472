App.bindAddAssortmentItemLink = () => {
  $('.add-assortment-item').on('click', function (e) {
    e.preventDefault();
    let url = $(e.target).data('url');
    let bottleId = $(e.target).data('bottle-id');
    let quantity = $('form#bottlePriceForm-' + bottleId + ' ' + 'input[id=cart_item_quantity]').val();
    $.ajax({
      url: url,
      data: {
        assortment: {
          assortment_item_attributes: {
            bottle_id: bottleId,
            quantity: quantity
          }
        }
      }
    })
  });
};

$(document).on('turbolinks:load', function() {
  App.bindAddAssortmentItemLink();
});
