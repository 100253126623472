// ALERT MESSAGE :: auto-close alert messages every x seconds
bindAlertMessagesAutoClosing = function(time) {
  let timelapse = time || 5000;
  $.each($('.alert.alert-dismissible').get().reverse(), function(index, alert) {
    setTimeout(function() {
      $(alert).alert('close');
    }, ((index + 1) * timelapse));
  })
};

$(document).ready(function() {
  bindAlertMessagesAutoClosing();
});