// this function is used to change text and color of a link
// regarding the success of the request
// it is used for the add to assortment link
App.showActionLinkStatus = (success, target, text, timer) => {
  let timelapse = timer || 1800;
  let actionLink = $(target);
  let actionLinkContent = $(actionLink[0]).html();
  let linkClass = success ? 'link-success' : 'link-failure';
  actionLink.html(`<span class='ft-dripicons' style='position:relative;top:1px'>S</span> ${text}`);
  actionLink.addClass(linkClass);
  setTimeout(function() {
    actionLink.html(actionLinkContent);
    actionLink.removeClass(linkClass);
  }, timelapse)
};