$(document).on('turbolinks:load', function() {
  //CLIENTS LIST SEARCH NAME LIKE
  $('#clientsIndexSearchInput').on('input', function(e) {
    let value = $(this).val();
    if (3 <= value.length || value.length == 0) {
      let form = $(this).parents('form:first')[0];
      Rails.fire(form, 'submit');
    }
  });

  $('.filter-control--checkbox').on('change', function() {
    let form = $(this).parents('form:first')[0];
    Rails.fire(form, 'submit');
  })
});