App.OrderForm = class {

  bindAddressFieldsSelectInput() {
    $('#shipping_addresses_select, #billing_addresses_select').on('change', function(elmt){
      let url = elmt.target.getAttribute('data-url');
      let addressId = elmt.target.value;
      let addressType = elmt.target.getAttribute('data-address-type');

      if (addressType) {
        $.get({
          url: url,
          data: {
            address_id: addressId,
            address_type: addressType
          }
        })
      }
    });
  }
};




$(document).on('turbolinks:load', function() {
  App.orderForm = new App.OrderForm();
  App.orderForm.bindAddressFieldsSelectInput();
});