// QUANTITY CONTROLS
// clicking on button or elmt increase or decrease closest input value
// created for Shop > bottles > quantities + and -

App.QuantityControls = class {
  bind() {
    $('.quantity-control').unbind().on('click', (event) => {
      event.preventDefault();
      this.changeQuantity(event);
    });

    $('.sampling-quantity-control').unbind().on('click', (event) => {
      event.preventDefault();
      this.changeSamplingQuantity(event);
    });

    $('.cart-item__sampling-quantity-input, .cart-item__free-quantity-input').unbind().on('change', (event) => {

      const $input = $(event.currentTarget);
      this.submitForm($input, parseInt($input.val()))
    })
  };

  changeQuantity(event) {
    let target = $(event.currentTarget);
    let coeff = target.data('container') || 1;
    let multiplier = target.data('val');
    let input = $($(target).parent()).find("input.quantity-input");
    if (input) {
      let newVal = parseInt(input.val()) + (coeff * multiplier);
      newVal = newVal < 0 ? 0 : newVal;
      input.val(newVal);

      if ($(input).data('submit') == true) {
        this.submitForm(input, newVal);
      }
    }
  };

  changeSamplingQuantity(event) {

    let target = $(event.currentTarget);
    let newQuantity = target.data('val');
    let input = $($(target).parent()).find("input.sampling-quantity-input");

    if (input) {
      let newVal = parseInt(input.val()) + newQuantity
      newVal = newVal < 0 ? 0 : newVal;
      input.val(newVal);

      if ($(input).data('submit') == true) {
        this.submitForm(input, newVal);
      }
    }
  };

  submitForm(input, value) {
    let url = $(input).data('url');
    let resourceName = $(input).data('resource-name');
    if (url) {
      $.ajax({
        url: url,
        type: 'PUT',
        data: {
          [resourceName]: {
            quantity: value
          }
        }
      })
    } else {
      let form = $(input).parents('form:first')[0];
      Rails.fire(form, 'submit');
    }
  }
};

// SHOW HIDE CHECKBOX CONTROL
// clicking on this control (a checkbox) will show or hide a target element (toggle .hidden class on elmt).
// created for Order > form > billing address same as shipping address

App.showHideCheckboxControl = (elmt) => {
  let control = $(elmt);
  let target = control.data('toggle-target');
  if (control.prop('checked')) {
    $(target).addClass('hidden');
  } else {
    $(target).removeClass('hidden');
  }
};

App.collapseAndClearCheckboxControl = (elmt) => {
  let control = $(elmt);
  let target = control.data('toggle-target');
  let targetInput = control.data('toggle-target') + ' ' + 'input';
  if (control.prop('checked')) {
    $(target).collapse('hide');
    $(targetInput).val("");
  } else {
    $(target).collapse('show');
  }
};