// this function is used to change background color and text of a submit button
// regarding the success of the request
// it is used for the add to cart button
App.showSubmitButtonStatus = (success, target, text) => {
  let submitButton = $(target);
  let submitButtonLabel = $(submitButton[0]).text();
  let buttonClass = success ? 'button-success' : 'button-failure';
  submitButton.text(text);
  submitButton.attr('disabled', true);
  submitButton.addClass(buttonClass);
  setTimeout(function() {
    submitButton.text(submitButtonLabel);
    submitButton.attr('disabled', false);
    submitButton.removeClass(buttonClass);
  }, 800)
};