// App init for front
    window.App = window.App || {};
    App.instances = App.instances || {};

// GOOGLE ANALYTICS
$(document).on("turbolinks:load", function() {
  gtag('config', 'G-R2RWGHEVTV', {'page_location': event.data.url});
})

// TOOLTIPS :: initialize tooltips
$(document).tooltip({
  selector: '.tooltip--grey',
  template: "<div class='tooltip tooltip--grey' role='tooltip'><div class='arrow'></div><div class='tooltip-inner'></div></div>"
});

// FORM SUBMITTING :: prevent from submitting form or actioning first form button
$(document).ready(function() {
  $(window).keydown(function(event){
    if(event.keyCode === 13) {
      // prevent from submitting form or actioning first form button
      event.preventDefault();
      if ($(event.target).data('submit') === true) {
        let input = event.target;
        let form = $(input).parents('form:first')[0];
        form.submit();
      }
      // blur focus on input when pressing 'Enter' but do not submit form
      if (event.target.localName === 'input') {
        $(event.target).blur()
      }
    }
  });

  // PASSWORD INPUT
  $('#passwordIcon').on('click', function(){
    var input = $("#passwordInput");

    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  })
});
